import React from 'react'
import loadable from '@loadable/component';
import LayoutTwo from '../components/layoutTwo';
import { Container, Row, Col } from 'react-bootstrap';

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const StaticIntro = loadable(() => import("../components/StaticIntro/StaticIntro"));
const ValuationThankYou = loadable(() => import("../components/ValuationThankYou/ValuationThankYou"));
const ValuationContact = loadable(() => import("../components/ValuationContact/ValuationContact"));

const InstantValuationResults = () => {
    return (
        <LayoutTwo>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule
                parent="Contact"
                currentPage="Instant Valuation"
            />

            <Container>
                <Row>
                    <Col lg={7}>
                        <StaticIntro
                            title="Instant Valuation"
                            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text."
                            staticClass="static-intro-secondary"
                            tag="inner-large"
                            noborderTag="no-border-tag"
                        />

                        <div className="d-md-none">
                            <ValuationContact tag="inner-form" />
                        </div>

                        <ValuationThankYou
                            address="Flat 3, Corbett House, Cathcart Road, London"
                            minimum_sale_estimation={360000}
                            minimum_rent_estimation={36000}
                            average_sale_estimation={400000}
                            average_rent_estimation={40000}
                            maximum_sale_estimation={440000}
                            maximum_rent_estimation={44000}
                        />
                    </Col>

                    <Col lg={1}></Col>

                    <Col lg={4} className="d-md-block d-none">
                        <ValuationContact tag="inner-form" />
                    </Col>
                </Row>
            </Container>
        </LayoutTwo>
    )
}

export default InstantValuationResults